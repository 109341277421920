<template>
  <div>
    <v-card>
      <v-card-title>
        Project Codes
        <v-spacer />
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table :headers="headers" :items="referralCodes" item-key="name" :search="search">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="$router.push({ path: `/mobile/projectCode/${item._id}/edit` })"
          >edit</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <div class="pt-4">
      <v-btn class="mr-2" @click="$router.push({ name: 'MobileProjectCodeCreate' })" color="primary">Create</v-btn>
      <v-btn class="mr-2" @click="$router.push({ name: 'MobileProjectCodeStatus' })" color="primary">Check Status</v-btn>
      <v-btn class="mr-2" @click="$router.push({ name: 'MobileProjectCodeAssign' })" color="primary">Overwrite Project Code</v-btn>
    </div>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();

export default {
  data() {
    return {
      referralCodes: [],
      search: "",
      headers: [
        { text: "Project Name", value: "name", sortable: true },
        { text: "Referral Code", value: "code", sortable: true },
        { text: "Created At", value: "created_at", sortable: true },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },

  mounted() {
    this.loadReferralCodes();
  },

  methods: {
    loadReferralCodes() {
      this.$setLoader();

      service.fetchProjectCodes().then(r => {
        this.referralCodes = r.data;
        this.$disableLoader();
      });
    }
  }
};
</script>

<style scoped>
#bt1 {
  float: right;
}
</style>